import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { FaTrash } from 'react-icons/fa';

function ResumeDetails() {
  const { positionId, resumeId } = useParams();
  const navigate = useNavigate();
  const [resume, setResume] = useState(null);

  useEffect(() => {
    const fetchResume = async () => {
      try {
        const response = await axiosInstance.get(`/api/v1/positions/${positionId}/${resumeId}`);
        setResume(response.data);
      } catch (error) {
        console.error('Error fetching resume', error);
      }
    };

    fetchResume();
  }, [positionId, resumeId]);

  const handleDeleteResume = async () => {
    try {
      await axiosInstance.delete(`/api/v1/positions/${positionId}/${resumeId}`);
      navigate(`/positions/${positionId}`);
    } catch (error) {
      console.error('Error deleting resume', error);
    }
  };

  if (!resume) {
    return <div>Loading...</div>;
  }

  const isProcessing = !resume.relevance;

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{resume.full_name || resume.file_name}</h2>
          <FaTrash
            onClick={handleDeleteResume}
            className="cursor-pointer text-red-600"
          />
        </div>
        <p>
          <a href={resume.download_url} className="text-blue-500 underline">
            {resume.file_name}
          </a>
        </p>
        {isProcessing ? (
          <p className="text-yellow-600">The resume is still being processed. Please check back later for the relevance score.</p>
        ) : (
          <>
            <p className="mb-4 italic text-gray-600">{resume.created_at}</p>
            <p className="mb-4 whitespace-pre-line">{resume.contact_info}</p>
            <p className="mb-4 font-bold">{resume.relevance}</p>
            <p className="mb-4 whitespace-pre-line">{resume.explanation}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default ResumeDetails;
