import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import CreatePosition from './components/CreatePosition';
import UploadResumes from './components/UploadResumes';
import PositionList from './components/PositionList';
import PositionDetails from './components/PositionDetails';
import ResumeDetails from './components/ResumeDetails';
import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';
import { TranslationProvider } from './contexts/TranslationContext';

function App() {
  return (
    <AuthProvider>
      <TranslationProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/create-position"
              element={
                <ProtectedRoute>
                  <CreatePosition />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload-resumes"
              element={
                <ProtectedRoute>
                  <UploadResumes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/positions/:id"
              element={
                <ProtectedRoute>
                  <PositionDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/positions/:positionId/resumes/:resumeId"
              element={
                <ProtectedRoute>
                  <ResumeDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <PositionList />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </TranslationProvider>
    </AuthProvider>
  );
}

export default App;
