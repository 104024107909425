import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosInstance from '../axiosConfig';
import uploadInstance from '../uploadConfig';
import { useTranslation } from '../contexts/TranslationContext';
import { FaTimes } from 'react-icons/fa';

function UploadResumes() {
  const [files, setFiles] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ completed: 0, total: 0 });
  const translations = useTranslation();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axiosInstance.get('/api/v1/positions/');
        setPositions(response.data.map((position) => ({
          value: position.id,
          label: position.title,
        })));
      } catch (error) {
        console.error('Error fetching positions', error);
      }
    };

    fetchPositions();
  }, []);

  const handleFileChange = (event) => {
    setFiles((prevFiles) => [...prevFiles, ...event.target.files]);
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    setLoading(true);
    setUploadProgress({ completed: 0, total: files.length });
    try {
      const uploadResponse = await axiosInstance.post('/api/v1/storage/upload_links', {
        file_names: files.map((file) => file.name),
      });

      const uploadLinks = uploadResponse.data.links;

      const uploadPromises = files.map((file, index) =>
        uploadInstance.put(uploadLinks[index], file, {
          headers: {
            'Content-Type': file.type,
          },
        }).then(() => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            completed: prevProgress.completed + 1,
          }));
        })
      );

      await Promise.all(uploadPromises);

      const blobs = uploadResponse.data.blob_names;
      const positionIds = selectedPositions.map((position) => position.value);
      await axiosInstance.post('/api/v1/resume/', {
        position_ids: positionIds,
        blobs,
      });

      // Clear the form and show success message
      setFiles([]);
      setSelectedPositions([]);
      setUploadSuccess(true);

      // Hide the success message after a few seconds
      setTimeout(() => setUploadSuccess(false), 3000);
    } catch (error) {
      console.error('Upload failed', error);
    } finally {
      setLoading(false);
    }
  };

  if (!translations || !translations.uploadResumes) {
    return null; // Or a loading indicator
  }

  return (
    <div className="min-h-screen bg-gray-100 relative">
      {loading && (
        <div className="absolute inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center z-50">
          <div className="text-white mb-4">
            {translations.uploadResumes.loading} {uploadProgress.completed}/{uploadProgress.total}
          </div>
          <div className="w-1/2 bg-gray-300 rounded">
            <div
              className="bg-blue-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l"
              style={{ width: `${(uploadProgress.completed / uploadProgress.total) * 100}%` }}
            >
              {Math.round((uploadProgress.completed / uploadProgress.total) * 100)}%
            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto p-4">
        <div className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-center text-2xl font-bold mb-6">{translations.uploadResumes.title}</h2>
          {uploadSuccess && (
            <div className="bg-green-100 text-green-800 p-4 rounded mb-4">
              {translations.uploadResumes.successMessage}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="files">
              {translations.uploadResumes.resumesLabel}
            </label>
            <input
              type="file"
              id="files"
              multiple
              onChange={handleFileChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <h3 className="text-gray-700 text-sm font-bold mb-2">{translations.uploadResumes.selectedFilesLabel}</h3>
            <ul className="list-disc list-inside">
              {files.map((file, index) => (
                <li key={index} className="flex justify-between items-center">
                  {file.name}
                  <FaTimes
                    onClick={() => handleFileRemove(index)}
                    className="cursor-pointer text-red-600 ml-2"
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="positions">
              {translations.uploadResumes.positionsLabel}
            </label>
            <Select
              id="positions"
              isMulti
              options={positions}
              value={selectedPositions}
              onChange={setSelectedPositions}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={handleUpload}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              disabled={loading}
            >
              {translations.uploadResumes.uploadButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadResumes;
