import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import { useTranslation } from '../contexts/TranslationContext';

function CreatePosition() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const translations = useTranslation();

  const handleCreatePosition = async () => {
    try {
      await axiosInstance.post('/api/v1/positions/', {
        title,
        description,
      });
      // Handle the response, redirect, etc.
    } catch (error) {
      console.error('Position creation failed', error);
    }
  };

  if (!translations || !translations.createPosition) {
    return null; // Or a loading indicator
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-4">
        <div className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-center text-2xl font-bold mb-6">
            {translations.createPosition.title}
          </h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              {translations.createPosition.titleLabel}
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              {translations.createPosition.descriptionLabel}
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="20"
              style={{ height: '500px' }}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={handleCreatePosition}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              {translations.createPosition.createButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePosition;
