import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../axiosConfig';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axiosInstance.get('/api/v1/me');
          setUsername(response.data.username);
          setIsLoggedIn(true);
        } catch (error) {
          console.error('Failed to fetch user info', error);
          setIsLoggedIn(false);
        }
      }
    };

    fetchUserInfo();
  }, []);

  const login = async (username, password) => {
    const response = await axiosInstance.post('/api/v1/login', { username, password });
    const { access_token } = response.data;
    localStorage.setItem('access_token', access_token);
    setUsername(username);
    setIsLoggedIn(true);
  };

  const logout = async () => {
    await axiosInstance.post('/api/v1/logout');
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
    setUsername('');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, username, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
