import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from '../contexts/TranslationContext';

function Navbar() {
  const { isLoggedIn, username, logout } = useAuth();
  const navigate = useNavigate();
  const translations = useTranslation();

  if (!translations || !translations.navbar) {
    console.log('Translations are not loaded');
    return null; // Or a loading indicator
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between">
        <div className="flex space-x-4">
          {isLoggedIn && (
            <>
              <Link to="/" className="text-white hover:bg-gray-700 px-3 py-2 rounded">
                {translations.navbar.positions}
              </Link>
              <Link to="/create-position" className="text-white hover:bg-gray-700 px-3 py-2 rounded">
                {translations.navbar.createPosition}
              </Link>
              <Link to="/upload-resumes" className="text-white hover:bg-gray-700 px-3 py-2 rounded">
                {translations.navbar.uploadResumes}
              </Link>
            </>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {isLoggedIn ? (
            <>
              <span className="text-white">{username}</span>
              <button
                onClick={handleLogout}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {translations.navbar.logout}
              </button>
            </>
          ) : (
            <Link to="/login" className="text-white hover:bg-gray-700 px-3 py-2 rounded">
              {translations.navbar.login}
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
