import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';

const TranslationContext = createContext();

export const useTranslation = () => {
  return useContext(TranslationContext);
};

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await axiosInstance.get('/api/v1/language/interface');
        setTranslations(response.data);
      } catch (error) {
        console.error('Error fetching translations', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTranslations();
  }, []);

  if (loading) {
    return <div>Loading translations...</div>;
  }

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};
