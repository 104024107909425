import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { FaPencilAlt, FaSave, FaTimes, FaTrash } from 'react-icons/fa';
import { useTranslation } from '../contexts/TranslationContext';

function PositionDetails() {
  const { id } = useParams();
  const [position, setPosition] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const translations = useTranslation();

  useEffect(() => {
    const fetchPosition = async () => {
      try {
        const response = await axiosInstance.get(`/api/v1/positions/${id}`);
        setPosition(response.data);
        setTitle(response.data.title);
        setDescription(response.data.description);
      } catch (error) {
        console.error('Error fetching position', error);
      }
    };

    fetchPosition();
  }, [id]);

  const handleUpdate = async () => {
    try {
      await axiosInstance.put(`/api/v1/positions/${id}`, {
        title,
        description,
      });
      setPosition((prevPosition) => ({ ...prevPosition, title, description }));
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating position', error);
    }
  };

  const handleDeleteResume = async (resumeId) => {
    try {
      await axiosInstance.delete(`/api/v1/positions/${id}/${resumeId}`);
      setPosition((prevPosition) => ({
        ...prevPosition,
        resumes: prevPosition.resumes.filter((resume) => resume.id !== resumeId),
      }));
    } catch (error) {
      console.error('Error deleting resume', error);
    }
  };

  const getRelevanceColor = (relevance) => {
    switch (relevance) {
      case 3:
        return 'bg-green-100';
      case 2:
        return 'bg-yellow-100';
      case 1:
        return 'bg-orange-100';
      case 0:
        return 'bg-red-100';
      default:
        return 'bg-gray-100';
    }
  };

  const sortedResumes = position
    ? position.resumes.sort((a, b) => (b.relevance_number || 0) - (a.relevance_number || 0))
    : [];

  if (!position) {
    return <div>{translations?.general?.loading || 'Loading...'}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-4">
        <div className="bg-white shadow-md rounded p-4 mb-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">
              {isEditing ? (
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="border rounded p-2 w-full"
                />
              ) : (
                <>
                  {position.title}
                  <FaPencilAlt
                    onClick={() => setIsEditing(true)}
                    className="inline ml-2 cursor-pointer text-gray-600"
                  />
                </>
              )}
            </h2>
          </div>
          <div className="relative">
            {isEditing ? (
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border rounded p-2 w-full h-64"
              />
            ) : (
              <div
                className="border rounded p-2 w-full h-64 overflow-y-auto whitespace-pre-line"
              >
                {position.description}
                <FaPencilAlt
                  onClick={() => setIsEditing(true)}
                  className="inline ml-2 cursor-pointer text-gray-600 absolute top-2 right-2"
                />
              </div>
            )}
          </div>
          {isEditing && (
            <div className="flex justify-end mt-4">
              <button
                onClick={handleUpdate}
                className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <FaSave className="inline mr-2" /> {translations?.positionDetails?.updateButton || 'Update'}
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                <FaTimes className="inline mr-2" /> {translations?.positionDetails?.cancelButton || 'Cancel'}
              </button>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          {sortedResumes.map((resume) => (
            <div
              key={resume.id}
              className={`relative block p-4 rounded mb-4 shadow-md ${getRelevanceColor(resume.relevance_number)}`}
            >
              <Link to={`/positions/${id}/resumes/${resume.id}`} className="block">
                <h4 className="text-lg font-bold">{resume.full_name || resume.file_name}</h4>
                <p>{resume.relevance_text || 'Under Processing'}</p>
              </Link>
              <FaTrash
                onClick={() => handleDeleteResume(resume.id)}
                className="absolute top-2 right-2 cursor-pointer text-red-600"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PositionDetails;
