import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { useTranslation } from '../contexts/TranslationContext';

function PositionList() {
  const [positions, setPositions] = useState([]);
  const translations = useTranslation();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axiosInstance.get('/api/v1/positions/');
        setPositions(response.data);
      } catch (error) {
        console.error('Error fetching positions', error);
      }
    };

    fetchPositions();
  }, []);

  if (!translations || !translations.positionList) {
    return null; // Or a loading indicator
  }

  const getRelevanceColor = (relevance) => {
    switch (relevance) {
      case 'resumes_relevant':
        return 'text-green-600';
      case 'resumes_likely_relevant':
        return 'text-yellow-600';
      case 'resumes_likely_not_relevant':
        return 'text-orange-600';
      case 'resumes_not_relevant':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">{translations.positionList.positions}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {positions.map((position) => (
            <Link
              key={position.id}
              to={`/positions/${position.id}`}
              className="bg-white shadow-md rounded p-4 block hover:shadow-lg transition-shadow duration-200"
            >
              <h3 className="text-xl font-bold mb-2">{position.title}</h3>
              <p>{translations.positionList.totalResumes}: {position.resumes_total}</p>
              <p className={getRelevanceColor('resumes_relevant')}>{translations.positionList.relative}: {position.resumes_relevant}</p>
              <p className={getRelevanceColor('resumes_likely_relevant')}>{translations.positionList.likelyRelative}: {position.resumes_likely_relevant}</p>
              <p className={getRelevanceColor('resumes_likely_not_relevant')}>{translations.positionList.likelyNotRelative}: {position.resumes_likely_not_relevant}</p>
              <p className={getRelevanceColor('resumes_not_relevant')}>{translations.positionList.notRelative}: {position.resumes_not_relevant}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PositionList;
